import React, { useState, useEffect, useRef } from 'react';
import { Search, Send, X } from 'lucide-react';
import './index.css';
import PrivacyPolicy from './privacy';
import Disclaimer from './disclaimer';

const Intro = ({ opacity, onArrowClick }) => (
  <div className="h-screen flex flex-col items-center justify-center bg-cover bg-center intro-image relative">
    <div className="text-center p-4 rounded-lg intro-text" style={{ color: '#800000' }}>
      <h1 className="text-4xl font-bold mb-4 italic">Welcome to</h1>
      <h1 className="text-6xl font-bold">The Date Concierge</h1>
    </div>
    <button
      onClick={onArrowClick}
      className="absolute bottom-8 mobile-bell-position animate-bounce text-white p-4 rounded-full"
      aria-label="Scroll to Fritz Avatar"
    >
      <img src="/bell.png" alt="Concierge Bell" width={40} height={40} />
    </button>
  </div>
);

const FritzAvatar = ({ opacity, onBegin, showButton }) => {
  return (
    <div
      className="fritz-avatar-container"
      style={{
        opacity,
        transition: 'opacity 0.3s ease-in-out',
      }}
    >
      <div className="fritz-avatar-content">
        <img src="/fritz-avatar.png" alt="Fritz Avatar" className="fritz-avatar-image" />
        {showButton && (
          <button
            onClick={onBegin}
            className="begin-button"
          >
            Click Here to Get Started
          </button>
        )}
      </div>
    </div>
  );
};

const Chat = ({ opacity }) => {
  const assistantStart = `Good day, my dear friend! I am Fritz, your trusty British butler and date planner extraordinaire. It's an absolute pleasure to make your acquaintance. Might I have the honour of knowing your name?`;
  const [messages, setMessages] = useState([
    { role: 'user', content: 'Hello Fritz' },
    { role: 'assistant', content: assistantStart }
  ]);
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [placeholder] = useState('Type your message here...');
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(scrollToBottom, [messages]);

  const sendMessage = async (e) => {
    e.preventDefault();
    if (input.trim() && !isLoading) {
      setIsLoading(true);
      const userMessage = { role: 'user', content: input };
      setMessages((prevMessages) => [...prevMessages, userMessage]);
      setInput('');

      try {
        const response = await fetch('/api/chat', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ messages: [...messages, userMessage] }),
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        const assistantMessage = { role: 'assistant', content: data.content[0].text };
        setMessages((prevMessages) => [...prevMessages, assistantMessage]);
      } catch (error) {
        console.error('Error calling backend:', error);
        const errorMessage = { role: 'assistant', content: 'Sorry, there was an error processing your request.' };
        setMessages((prevMessages) => [...prevMessages, errorMessage]);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div
      className="bg-chat-color border-2 border-black rounded-lg shadow-md chat-component overflow-hidden"
      style={{ opacity, transition: 'opacity 0.3s ease-in-out' }}
    >
      <div className="h-96 overflow-y-auto p-4 font-custom">
        {messages.map((message, index) => (
          <div key={index} className={`mb-4 ${message.role === 'user' ? 'text-right' : 'text-left'}`}>
            <div className={`inline-block p-3 rounded-lg ${message.role === 'user' ? 'bg-wine text-white' : 'assistant-message'}`}>
              {message.content}
            </div>
          </div>
        ))}
        {isLoading && <div className="text-center text-wine">Fritz is thinking...</div>}
        <div ref={messagesEndRef} />
      </div>
      <form onSubmit={sendMessage} className="p-4 border-t font-custom">
        <div className="flex">
          <input
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            className="flex-1 p-2 border rounded-l-full border-wine focus:outline-none focus:border-wine text-wine placeholder-wine"
            placeholder={placeholder}
            disabled={isLoading}
          />
          <button type="submit" className="bg-wine text-white p-2 rounded-r-full" disabled={isLoading}>
            {isLoading ? <Search size={24} /> : <Send size={24} />}
          </button>
        </div>
      </form>
    </div>
  );
};

const TipFritzButton = () => (
  <button
    onClick={() => window.open("https://www.buymeacoffee.com/ericmags", '_blank', 'noreferrer')}
    className="bg-wine text-white p-4 rounded-full tip-fritz-button"
    style={{
      backgroundColor: '#800000',
      color: 'white',
      border: '0.125em solid #800000',
      fontSize: '1.15rem', // Equivalent to 20px
    }}
  >
    {"🤝 Tip Fritz"}
  </button>
);

const Footer = () => {
  const [isPrivacyPopupOpen, setIsPrivacyPopupOpen] = useState(false);
  const [isDisclaimerPopupOpen, setIsDisclaimerPopupOpen] = useState(false);

  const togglePrivacyPopup = () => setIsPrivacyPopupOpen(!isPrivacyPopupOpen);
  const toggleDisclaimerPopup = () => setIsDisclaimerPopupOpen(!isDisclaimerPopupOpen);

  return (
    <>
      <footer className="bg-[#EFE2CD] text-[#800000] py-4">
        <div className="container mx-auto px-4">
          <div className="flex justify-center items-center">
            <div className="flex space-x-4 items-center">
              <button onClick={togglePrivacyPopup} className="hover:underline">
                Privacy Policy
              </button>
              <button onClick={toggleDisclaimerPopup} className="hover:underline">
                Disclaimer
              </button>
              <a href="mailto:dateconciergefritz@gmail.com" className="hover:underline">Contact</a>
            </div>
          </div>
        </div>
      </footer>

      {isPrivacyPopupOpen && (
        <PopupContent
          title="Privacy Policy"
          content={<PrivacyPolicy />}
          onClose={togglePrivacyPopup}
        />
      )}

      {isDisclaimerPopupOpen && (
        <PopupContent
          title="Disclaimer"
          content={<Disclaimer />}
          onClose={toggleDisclaimerPopup}
        />
      )}
    </>
  );
};

const PopupContent = ({ title, content, onClose }) => (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50" onClick={onClose}>
    <div
      className="bg-white text-black p-8 rounded-lg shadow-lg max-w-md w-full mx-4 max-h-[80vh] overflow-y-auto"
      onClick={(e) => e.stopPropagation()}
    >
      <div className="relative">
        <button
          onClick={onClose}
          className="absolute top-0 right-0 text-black hover:text-gray-500"
        >
          <X size={24} />
        </button>
        <h2 className="text-2xl font-bold mb-4 pr-8">{title}</h2>
        {content}
      </div>
    </div>
  </div>
);

const App = () => {
  const [introOpacity, setIntroOpacity] = useState(1);
  const [fritzOpacity, setFritzOpacity] = useState(0);
  const [chatOpacity, setChatOpacity] = useState(0);
  const [chatVisible, setChatVisible] = useState(false);
  const chatRef = useRef(null);
  const fritzRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);

    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;

      // Fade out intro
      const introOpacity = Math.max(0, 1 - scrollPosition / windowHeight);
      setIntroOpacity(introOpacity);

      // Fade in Fritz avatar
      const fritzOpacity = Math.min(1, Math.max(0, (scrollPosition - windowHeight / 2) / (windowHeight / 2)));
      setFritzOpacity(fritzOpacity);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToFritz = () => {
    fritzRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleBegin = () => {
    setChatVisible(true);
    setChatOpacity(1);
  };

  return (
    <div className="font-sans min-h-screen" style={{ backgroundColor: '#EFE2CD' }}>
      <Intro opacity={introOpacity} onArrowClick={scrollToFritz} />
      <div className="relative z-20">
        <div ref={fritzRef} style={{ marginBottom: '0' }}>
          <FritzAvatar opacity={fritzOpacity} onBegin={handleBegin} showButton={!chatVisible} />
        </div>
        {chatVisible && (
          <div className="container mx-auto px-4 py-0 fade-transition" ref={chatRef}>
            <Chat opacity={chatOpacity} />
            <div className="flex justify-center mt-8">
              <TipFritzButton />
            </div>
          </div>
        )}
      </div>
      <div className="h-8"></div> {/* Added padding at the bottom */}
      <Footer />
    </div>
  );
};

export default App;