import React from 'react';

const disclaimer = `
This tool can provide you with helpful recommendations when planning a date,
night out with a friend, etc. 
This tool cannot book reservations for you or make purchases on your behalf.
It is currently not connected to live data sources meaning some information
may be stale and is dependent on the data it was trained on. We are working
to implement additional features to ensure all recommendations are valid in the future!
Contact us if you have any questions.
Last Updated: 7/31/24
`;

const Disclaimer = () => (
    <div>
      {/* Your privacy policy content goes here */}
      <p>{disclaimer}</p>
    </div>
  );
  
  export default Disclaimer;
